<template>
  <a-card :bordered="false">
    <a-button type="primary" v-has:pdaVersionAdd="()=>{$router.push('/pdaManage/version/add?type=add')}" class="m-b-20">
      <PlusOutlined />
      发布新版本
    </a-button>
    <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
      :onFinish='pageSearchChange' layout='inline'>
      <a-form-item label="版本号" name='version_sn	'>
        <a-input v-model:value="search.version_sn	" style="width:250px" placeholder="请输入版本号进行搜索"></a-input>
      </a-form-item>
      <a-form-item label="发布时间" name='time'>
        <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" htmlType='submit'>搜索</a-button>
      </a-form-item>
    </a-form>
    <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
      <template v-slot:status="{ record }">
        {{record.status?"启用":"停用"}}
      </template>
      <template v-slot:action="{ record }">
        <a-button type="primary" ghost size="small" v-has:pdaVersionStatus="()=>versionStatus(record)">{{record.status?"停用":"启用"}}</a-button>
        <a-button class="m-l-10" type="primary" size="small" v-has:pdaVersionEdit="()=>{$router.push('/pdaManage/version/edit?type=edit&id='+record.id)}" v-if="!record.status">编辑</a-button>
        <a-button class="m-l-10" type="primary" danger size="small" v-has:pdaVersionDel="()=>versionDel(record)" v-if="!record.status">删除</a-button>
      </template>
    </a-table>
    <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
      :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
      @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
  </a-card>
</template>
<script>
  import { PlusOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { message } from "ant-design-vue";
  import { $iscode } from "@/utils/app";
  import {
    pdaVersionList,
    pdaVersionDel,
    pdaVersionStatus
  } from "@/api/pda.js";

  // 初始化默认筛选项数值
  let defSearch = {
    version_sn	:"",
    create_start_time: "",
    create_end_time: "",
    page: 1,
    limit: 10,
  };
  export default {
    components: { PlusOutlined },
    setup() {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        listLoading: false,
        total: 0
      });
      let columns = [
        {
          title: "版本号",
          dataIndex: "version_sn",
          key: "version_sn",
        },
        {
          title: "更新内容",
          dataIndex: "note",
          key: "note",
        },
        {
          title: "更新状态",
          dataIndex: "status",
          key: "status",
          slots: { customRender: "status" },
        },
        {
          title: "发布时间",
          dataIndex: "updated_at",
          key: "updated_at",
        },
        {
          title: "操作",
          key: "action",
          fixed:"right",
          slots: { customRender: "action" },
        },
      ];
      const $Modal = inject("$Modal");
      // 时间筛选
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.create_start_time = e[0];
          search.value.create_end_time = e[1];
        } else {
          search.value.create_start_time = "";
          search.value.create_end_time = "";
        }
      };
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData();
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData();
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData();
      };

      const initData = async () => {
        state.listLoading = true;
        data.value = [];
        try {
          let res = await pdaVersionList({...search.value}).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.message);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      // 启用/停用
      const versionStatus = (data) => {
        $Modal.confirm({
          title: "提示",
          content:"您确定要"+(data.status?"停用":"启用")+"该版本吗？",
          centered:true,
          onOk: async () => {
            let res = await pdaVersionStatus(data.id).then((res) => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData();
            } else {
              message.error(res.msg);
            }
          },
        });
      };
      // 删除
      const versionDel = (data) => {
        $Modal.confirm({
          title: "提示",
          content:"您确定要删除该版本吗？",
          centered:true,
          onOk: async () => {
            let res = await pdaVersionDel(data.id).then((res) => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData();
            } else {
              message.error(res.msg);
            }
          },
        });
      };
      onMounted(() => {
        initData();
      });

      return {
        ...toRefs(state),
        search,
        data,
        columns,
        onChange,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        initData,
        versionStatus,
        versionDel
      };
    },
  };
</script>