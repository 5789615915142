
import axios from '@/utils/request'
// Pda管理 - 激活码列表
export const deviceList = (params) => {
    return axios.request({
        url: 'pda/activeCode',
        method: 'get',
        params
    })
};
// Pda管理 - 创建激活码
export const createDeviceCode = (data) => {
    return axios.request({
        url: 'pda/activeCode',
        method: 'post',
        data
    })
};
// Pda管理 - 删除激活码
export const delDeviceCode = (id) => {
    return axios.request({
        url: 'pda/activeCode/' + id,
        method: 'DELETE',
    })
};
// Pda管理 - 激活码操作日志列表
export const deviceLogs = (params) => {
    return axios.request({
        url: 'pda/activeCode',
        method: 'get',
        params
    })
};


// Pda管理 - 隐形码列表
export const invisibleList = (params) => {
    return axios.request({
        url: 'pda/invisible',
        method: 'get',
        params
    })
};
// Pda管理 - 隐形码详情
export const invisibleDetail = (id) => {
    return axios.request({
        url: 'pda/invisible/' + id,
        method: 'get'
    })
};
// Pda管理 - 创建隐形码
export const InvisibleAdd = (data) => {
    return axios.request({
        url: 'pda/invisible',
        method: 'post',
        data
    })
};
// Pda管理 - 更新隐形码
export const InvisibleEdit = (data) => {
    return axios.request({
        url: 'pda/invisible/' + data.id,
        method: 'PUT',
        data
    })
};
// Pda管理 - 更新隐形码状态
export const InvisibleStatus = (data) => {
    return axios.request({
        url: 'pda/invisible/' + data.id+'/'+data.status,
        method: 'PATCH',
        data
    })
};
// Pda管理 - 删除激活码
export const delInvisibleCode = (id) => {
    return axios.request({
        url: 'pda/invisible/' + id,
        method: 'DELETE',
    })
};


/**
 * 
 * PDA版本管理
 */

// Pda版本管理 - 列表
export const pdaVersionList = (params) => {
    return axios.request({
        url: 'v2/pda_app',
        method: 'get',
        params
    })
};
// Pda版本管理 - 详情
export const pdaVersionDetail = (params) => {
    return axios.request({
        url: 'v2/pda_app/'+params.id,
        method: 'get',
        params
    })
};
// Pda版本管理 - 创建
export const pdaVersionAdd = (data) => {
    return axios.request({
        url: 'v2/pda_app',
        method: 'post',
        data
    })
};
// Pda版本管理 - 编辑
export const pdaVersionEdit = (data) => {
    return axios.request({
        url: 'v2/pda_app/'+data.id,
        method: 'PATCH',
        data
    })
};
// Pda版本管理 - 删除
export const pdaVersionDel = (id) => {
    return axios.request({
        url: 'v2/pda_app/' + id,
        method: 'DELETE',
    })
};
// Pda版本管理 - 启用/禁用
export const pdaVersionStatus = (id) => {
    return axios.request({
        url: 'v2/pda_app/status/' + id,
        method: 'post',
    })
};